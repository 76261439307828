body{
    background: #E9E9FF;
}


.grow {
    padding: 5px 5px 5px 5px;
    /*position: relative;*/
    transition: transform  0.5s;
    -webkit-transition: transform  0.5s;
}
.grow:hover {
    transform: scale(1.04);
}
